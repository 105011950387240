
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-5 col-md-4">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2 text-end">
                      รหัสอะไหล่:
                      <b-form-input
                        v-model="filter.partCode"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>

                  <div class="col-sm-3 col-md-5 text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;

                    <router-link
                      :to="{ name: 'add-PartLostSale' }"
                      class="btn btn-primary"
                    >
                      <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูล
                    </router-link>
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <tableData :fields="fields" :items="rowParts">
                <template #cell(price)="rowParts">
                  <p class="text-end">{{ Number(rowParts.item.price).toLocaleString() }}</p>
                </template>
                <template #cell(index)="rowParts" v-if="this.currentPage > 1">
                  {{ rowParts.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowParts" v-else>
                  {{ rowParts.index + 1 }}
                </template>
                <template #cell(plsDate)="rowParts">
                  <changeDate :date="rowParts.item.plsDate"></changeDate>
                </template>
                <template #cell(cause)="rowParts">
                  <span v-if="rowParts.item.cause === 'OOS'"> ขาดสต๊อก </span>
                  <span v-if="rowParts.item.cause === 'NS'"> ไม่สต๊อก </span>
                </template>
                <template #cell(action)="rowParts">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                          name: 'edit-PartLostSale',
                          params: { plsId: rowParts.item.plsIdBase64 },
                        }"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>

                      <a
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowParts.item.plsId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>

              <br />

              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col-md-12">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
import ChangeDate from "@/components/changeDateComponent";
// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: "สูญเสียโอกาสทางการขาย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect,
    ChangeDate,
  },

  data() {
    return {
      loading: undefined,

      title: "สูญเสียโอกาสทางการขาย",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: "สูญเสียโอกาสทางการขาย",
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowParts: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: {
        partCode: "",
        branchId: "",
      },
      branchIdSearch: "",
      localDataBranchId: [],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "partCode",
          label: "รหัสอะไหล่",
          sortable: true,
        },

        {
          key: "partNameTh",
          label: "อะไหล่",
          sortable: true,
        },

        {
          key: "plsDate",
          label: "วันที่",
        },
        {
          key: "cause",
          label: "สาเหตุ",
        },
        {
          key: "price",
          label: "ราคาขาย",
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowParts = this.items.length;
  },
  created() {
    this.getLocalData();
    this.getPartsLostSale();
  },
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;

      // console.log("สาขาาาาาา", this.localDataBranchId);
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.branchIdSearch.branchId];
      }
      this.getPartsLostSale();
    },
    onFiltered(filteredItems) {
      this.rowParts = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowParts) {
      this.selected = rowParts;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getPartsLostSale();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getPartsLostSale();
    },

    getPartsLostSale: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-lost-sale", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            partCode: this.filter.partCode,
            branchId: this.localDataBranchId,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
          // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    deleteParts: function (rowParts) {
      this.partData = rowParts;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/part-lost-sale/delete", {
          data: {
            plsId: this.partData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getPartsLostSale();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowParts) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParts(rowParts);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
